.login-modal {
    .ant-modal-content {
        padding: 0;
        background-color: #222;
        border: 2px solid #c81d1d;
        border-radius: 8px;
        overflow: hidden;

        .ant-modal-header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background: radial-gradient(#5f0000, #300000);
            border-radius: 0;
            border-bottom: 3px solid #c81d1d;

            .ant-modal-title {
                color: rgba(255, 255, 255, 0.8);
                font-weight: 800;
            }
        }

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            padding: 10px 20px;
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            font-weight: 700;

            .btn-close-red {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 20px;
                    color: #000;
                    background-color: gold;
                    border: none;
                    border-radius: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.5s;

                    &:hover {
                        color: rgba(255, 255, 255, 0.8);
                        background-color: #4c0000;
                    }
                }
            }
        }
    }
}
