.transaction-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tc-header-container {
        width: 100%;
        height: 10%;
    }

    .tc-main-container {
        width: 100%;
        height: 90%;
        //padding: 24px;

        .tc-table {
            border: 1px solid gold;
            background-color: rgb(0, 0, 0, 0.7);
            
            .transaction-history {
                // padding: 5px;
                // background-color: #141414;
                // border-radius: 8px;
            
                .ant-table {
                    color: #fff;
                    background-color: transparent !important;
                    border: 1px solid var(--primary-color);
                    border-radius: 8px !important;
                    overflow: hidden;
                }
            
                .ant-table-thead > tr > th {
                    color: #fff !important;
                    background-color: var(--primary-color) !important;
                    border: none;
                }
            
                .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
                    color: #fff;
                    background-color: rgba(255, 255, 255, 0.1) !important;
                }
            
                .ant-empty-normal {
                    color: #fff !important;
                    background-color: transparent !important;
                }
            
                .ant-table-wrapper .ant-table-tbody > tr > th,
                .ant-table-wrapper .ant-table-tbody > tr > td {
                    border-color: rgba(188, 188, 188, 0.3);
                }
            
                .ant-table-wrapper .ant-table-tbody > tr:last-child > th,
                .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
                    border: none;
                }
            
                .ant-empty-normal .ant-empty-description {
                    color: rgba(255, 255, 255, 0.8);
                }
            
                .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > th,
                .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td,
                .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
                    color: #fff !important;
                    background-color: transparent !important;
                }
            
                .ant-table-tbody > tr.ant-table-placeholder:hover > td {
                    background: #222;
                }
            
                .ant-pagination {
                    display: flex;
                    justify-content: center;
            
                    //btn-prev and btn-next
                    .ant-pagination-prev .ant-pagination-item-link,
                    .ant-pagination-next .ant-pagination-item-link {
                        color: #fff !important;
                        background: transparent;
                        border: none;
                        outline: 2px solid #fff;
                        border-radius: 50%;
                    }
            
                    //btn-number page
                    .ant-pagination-item {
                        color: #fff !important;
                        background: transparent;
                        border: none;
                        outline: 2px solid #fff;
                        border-radius: 50%;
                    }
            
                    .ant-pagination-item a {
                        color: #fff;
                    }
            
                    .ant-pagination-item-active,
                    .ant-pagination-item:hover {
                        outline: 2px solid var(--primary-color);
                    }
            
                    .ant-pagination-item-active a,
                    .ant-pagination-item a:hover {
                        color: var(--primary-color);
                    }
            
                    //ellipsis
                    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
                    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
                        color: #fff;
                    }
            
                    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
                    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
                        color: var(--primary-color);
                    }
            
                    //page size selector
                    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                        color: #fff;
                        background: transparent;
                        outline: 2px solid #fff;
                        border: none;
                        border-radius: 50px;
                    }
            
                    .ant-select-arrow {
                        color: #fff;
                    }
            
                    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                        border: none;
                        outline: 2px solid #fff;
                    }
                }
            }
            
        }
    }
}

.neon-hr {
    background: #8f7421;
    border: 1px solid #8f7421;
    box-shadow: 0 0 15px #f7d37d;
    filter: brightness(1.8);
    height: 5px;
    width: 100%;
}


.tab {
    padding: 10px 0;
    color: #f7d37d;
    background-color: black;
    width: 100%;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-weight: 700;
        transition: 0.5s;
        filter: brightness(0.8);
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }

        :is(&:hover, &.true) {
            filter: brightness(1);
            color: var(--secondary-color);
        }
    }
}
