.v-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .logo-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between; /* Distribute space between elements */
        align-items: center;
        background: linear-gradient(to right, #4a0100, #ce0100, #ce0100, #4a0100);

        .logo-img {
            height: 100%;
            aspect-ratio: 3/1;
            margin-left: 20px;
        }

        .player-btn-container {
            height: 60%;
            aspect-ratio: 3/1;
            cursor: pointer;
            position: relative;
            margin-right: 50px;

            .player-btn-img {
                height: 115%;
                width: 130%;
                aspect-ratio: 3/1;
            }
            .player-text {
                color: gold;
                position: absolute;
                top: 50%;
                left: 60%;
                transform: translate(-20%, -35%);
            
                /* Add these properties for text truncation */
                white-space: nowrap; /* Prevents the text from wrapping */
                overflow: hidden; /* Ensures that overflow is hidden */
                text-overflow: ellipsis; /* Adds an ellipsis at the point of truncation */
                max-width: 70px; /* Adjust this width as needed */
            
                /* Fix the font size using a fixed unit */
                font-size: 16px; /* Adjust this value to your needs */
                
                /* Disable zoom from changing the font size */
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
            }

            /* Media query for small devices like phones */
            @media (max-width: 800px) {
                .player-text {
                    font-size: 14px; /* Adjust for smaller screens */
                }
            }

            /* Media query for small devices like phones */
            @media (max-width: 600px) {
                .player-text {
                    font-size: 12px; /* Adjust for smaller screens */
                }
            }
            
            /* Media query for very small devices */
            @media (max-width: 400px) {
                .player-text {
                    font-size: 10px; /* Further adjust for smaller screens */
                }
            }
            
            /* Media query for very small devices */
            @media (max-width: 200px) {
                .player-text {
                    font-size: 8px; /* Further adjust for smaller screens */
                }
            }
        }
        .balance-btn-container {
            height: 60%;
            aspect-ratio: 3/1;
            cursor: pointer;
            position: relative;
            margin-left: 30px;

            .balance-btn-img {
                height: 115%;
                width: 130%;
                aspect-ratio: 3/1;
            }

            .balance-text {
                color: gold;
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translate(-30%, -32.5%);
                
                /* Add these properties for text truncation */
                white-space: nowrap; /* Prevents the text from wrapping */
                overflow: hidden; /* Ensures that overflow is hidden */
                text-overflow: ellipsis; /* Adds an ellipsis at the point of truncation */
                max-width: 70px; /* Adjust this width as needed */
            
                /* Fix the font size using a fixed unit */
                font-size: 16px; /* Adjust this value to your needs */
                
                /* Disable zoom from changing the font size */
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
            }

            /* Media query for small devices like phones */
            @media (max-width: 800px) {
                .balance-text {
                    font-size: 14px; /* Adjust for smaller screens */
                }
            }

            /* Media query for small devices like phones */
            @media (max-width: 600px) {
                .balance-text {
                    font-size: 12px; /* Adjust for smaller screens */
                }
            }
            
            /* Media query for very small devices */
            @media (max-width: 400px) {
                .balance-text  {
                    font-size: 10px; /* Further adjust for smaller screens */
                }
            }
            
            /* Media query for very small devices */
            @media (max-width: 200px) {
                .balance-text {
                    font-size: 8px; /* Further adjust for smaller screens */
                }
            }
        }
        .refresh-text-container {
            height: 60%;
            aspect-ratio: 2/1;
            cursor: pointer;

            .refresh-text-img {
                height: 100%;
                aspect-ratio: 2/1;
            }
        }
        .refresh-btn-container {
            height: 60%;
            aspect-ratio: 1/1;
            cursor: pointer;

            .refresh-btn-img {
                height: 120%;
                width: 120%;
                aspect-ratio: 1/1;
            }
        }
    }

    .menu-container {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.h-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #4a0100, #ce0100, #ce0100, #4a0100);

    .balance-container {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 5px;

        .refresh-btn-container {
            height: 60%;
            aspect-ratio: 1/1;
            cursor: pointer;

            .refresh-btn-img {
                height: 100%;
                aspect-ratio: 1/1;
            }
        }
        .refresh-text-container {
            height: 60%;
            aspect-ratio: 3/1;

            .refresh-text-img {
                height: 100%;
                aspect-ratio: 3/1;
            }
        }
        .balance-btn-container {
            height: 60%;
            aspect-ratio: 4/1;
            position: relative;

            .balance-btn-img {
                height: 100%;
                aspect-ratio: 4/1;
            }
            .balance-text {
                color: gold;
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translate(-50%, -50%);
                font-family: Arial;
                font-weight: bolder
            }
        }
    }

    .logo-container {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;

        .logo-img {
            height: 100%;
            aspect-ratio: 3/1;
        }
    }

    .menu-container {
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 5px;

        .player-btn-container {
            height: 60%;
            aspect-ratio: 4/1;
            position: relative;

            .player-btn-img {
                height: 100%;
                aspect-ratio: 4/1;
            }
            .player-text {
                color: gold;
                position: absolute;
                top: 50%;
                left: 60%;
                transform: translate(-50%, -50%);
                font-family: Arial;
                font-weight: bolder
            }
        }
        .setting-btn-container {
            height: 60%;
            aspect-ratio: 1/1;
            cursor: pointer;

            .setting-btn-img {
                height: 100%;
                aspect-ratio: 1/1;
            }
        }
        .logout-btn-container {
            height: 60%;
            aspect-ratio: 1/1;
            cursor: pointer;

            .logout-btn-img {
                height: 100%;
                aspect-ratio: 1/1;
            }
        }
    }
}
