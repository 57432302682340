.v-angpao-modal-container {
  width: 100%;
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    width: 100%;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Ensure the body takes the full height of the modal
    width: 100%;
  }

  .ant-modal-header,
  .ant-modal-footer {
    background-color: transparent;
    border: none;
  }

  .modal-image {
    max-width: 80vw;
    max-height: 80vh;
  }

  .modal-animated-image {
    max-width: 80vw;
    max-height: 80vh;
    animation-name: angpao;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Optional: Keeps the final state of the animation
  }

  .receive-btn {
    z-index: 100;
    animation-name: angpao;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Optional: Keeps the final state of the animation
  }

  @keyframes angpao {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .angpao-img {
    position: relative;
    height: 60vh;
    width: clamp(400px, 55vw, 800px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #angpao {
      position: absolute;
      object-fit: unset;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  .receive {
    text-align: center;
    z-index: 2;
    width: 100%;
    position: absolute;
    top: 80%;

    img {
      width: 60%;
    }
  }

  .image-button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none; // Remove default button styling (optional)
    background: none; // Transparent background (optional)
    padding: 0; // Remove default button padding (optional)
    color: white;
    font-size: 20vw;
    font-weight: bold;
    z-index: 2;
  }

  .unanimated-text {
    width: 95%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    text-align: center;
    font-size: 15vw;
    font-weight: bold;
    white-space: nowrap;
    color: gold;
  }

  .animated-text {
    position: absolute;
    bottom: 27.5vh;
    white-space: nowrap;
    color: gold;
    animation-name: v-text;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Keeps the final state of the animation
  }

  @keyframes v-text {
    0% {
      transform: translate(0, 0) scale(1); // Start from current position
    }
    20% {
      transform: translate(5vw, 5vh) scale(1); // Move 10% of viewport width and height
    }
    99% {
      transform: translate(-26vw, -31vh) scale(0.25); // Move to the destination using vw and vh
    }
    100% {
      transform: translate(-26vw, -31vh) scale(0.05); // Maintain position
      visibility: hidden; // Hide at the end
    }
  }
}

.h-angpao-modal-container {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Ensure the body takes the full height of the modal
  }

  .ant-modal-header,
  .ant-modal-footer {
    background-color: transparent;
    border: none;
  }

  .modal-image {
    max-width: 80vw;
    max-height: 80vh;
  }

  .modal-animated-image {
    max-width: 80vw;
    max-height: 80vh;
    animation-name: angpao;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Optional: Keeps the final state of the animation
  }

  .receive-btn {
    z-index: 2;
    animation-name: angpao;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Optional: Keeps the final state of the animation
  }
  @keyframes angpao {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .angpao-img {
    position: relative;
    display: inline-block;
  }

  .image-button {
    position: absolute;
    top: 72.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none; // Remove default button styling (optional)
    background: none; // Transparent background (optional)
    padding: 0; // Remove default button padding (optional)
    color: white;
    font-size: 6.5vw;
    font-weight: bold;
  }

  .unanimated-text {
    position: relative;
    bottom: 35vh;
    white-space: nowrap;
    color: gold;
  }

  .animated-text {
    position: absolute;
    bottom: 45vh;
    white-space: nowrap;
    color: gold;
    animation-name: h-text;
    animation-duration: 2s;
    animation-fill-mode: forwards; // Keeps the final state of the animation
  }

  @keyframes h-text {
    0% {
      transform: translate(0, 0) scale(1); // Start from current position
    }
    20% {
      transform: translate(5vw, 5vh) scale(1); // Move 10% of viewport width and height
    }
    99% {
      transform: translate(-25vw, -21vh) scale(0.5); // Move to the destination using vw and vh
    }
    100% {
      transform: translate(-25vw, -21vh) scale(0.1); // Maintain position
      visibility: hidden; // Hide at the end
    }
  }
}
