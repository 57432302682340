.login-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .login-logo {
        width: calc(100% / 3);
        height: calc(100% / 3);
        max-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        .login-logo-img {
            height: calc(100% / 2);
            aspect-ratio: 2.5/1;
        }
    }

    .login-wrapper {
        width: calc(100% / 3 * 2);
        max-width: 600px;
        height: calc(100% / 3 * 2);
        max-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px solid #c81d1d;
        border-radius: 14px;
        gap: 20px;
        background: radial-gradient(#5f0000, #300000);

        .login-form {
            width: 80%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 3%;

            .ant-input-affix-wrapper {
                background: linear-gradient(to right, #300000, #5f0000, #300000);
                border-radius: 0;
                border: 0;
                border: 2px solid #c81d1d;

                .ant-input-prefix {
                    color: #ddd;
                }

                &.ant-input-affix-wrapper-focused {
                    box-shadow: none;
                }

                .ant-input-suffix {
                    span {
                        color: #ddd;
                    }
                }
            }

            .ant-input {
                color: #ddd;

                &::placeholder {
                    color: #ddd;
                }
            }

            .ant-btn {
                border: 0;
                background: linear-gradient(to bottom, #e00000, #4c0000);
                color: white;
                font-family: "KrossNeueGrotesk-Heavy";
            }
        }
    }

    .apk-download{
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
}
