.v-lobby-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 5px;
    overflow: hidden;

    .overlay-img {
      position: absolute;
      bottom: 5%;
      right: 5%;
      max-width: 200px;
      max-height: 200px;
      width: 100%;
      height: 100%;
      object-fit: cover; // 确保图像覆盖整个容器
      z-index: 10; // 设定一个较高的 z-index 使其覆盖在其他元素之上

      // Animation applied here
      animation: pulse 2s infinite ease-in-out;
    }

    .click-area {
      position: absolute;
      bottom: 5%;
      right: 5%;
      width: 170px; // 小的点击区域
      height: 170px;
      z-index: 11; // 确保它在图像之上
      background-color: transparent; // 点击区域为透明
      cursor: pointer;
    }

    // Define keyframes for pulsating animation
    @keyframes pulse {
      0% {
        transform: scale(1); // Default size
      }
      50% {
        transform: scale(1.2); // Increase to 120% of the original size
      }
      100% {
        transform: scale(1); // Return to original size
      }
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .lb-header-container {
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lb-footer-container {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lb-announcement-container {
        width: 100%;
        height: 5%; 
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lb-game-cat-container {
        width: 100%;
        height: 12.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .lb-game-container {
        width: 100%;
        height: 62.5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lb-empty-container {
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.h-lobby-container {
    position: relative; // 确保子元素相对于这个容器定位
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;

    .overlay-img {
        position: absolute;
        bottom: 5%;
        right: 5%;
        max-width: 15vw; // Set max width as a percentage of the viewport width
        max-height: 30vh; // Set max height as a percentage of the viewport height
        width: 100%; // Maintain width as 100% of its container
        height: auto; // Maintain aspect ratio
        // object-fit: cover; // Ensure the image covers the entire container
        z-index: 10; // Set a high z-index to overlay on other elements
      
        // Animation applied here
        animation: pulse 2s infinite ease-in-out;
    }

    .click-area {
        position: absolute;
        bottom: 10%;
        right: 6%;
        max-width: 12vw;
        max-height: 18vh;
        width: 50%;
        height: 100%;
        z-index: 11;
        background-color: transparent;
        cursor: pointer;
        opacity: 0.5;
        transform: rotate(60deg);
    }

    // Define keyframes for pulsating animation
    @keyframes pulse {
      0% {
        transform: scale(1); // Default size
      }
      50% {
        transform: scale(1.2); // Increase to 120% of the original size
      }
      100% {
        transform: scale(1); // Return to original size
      }
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .lb-header-container {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lb-main-container {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;

        .lb-game-cat-container {
            width: 15%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .lb-angpao-container {
                width: 100%;
                height: 85%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .lb-sub-container {
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .lb-announcement-container {
                width: 100%;
                height: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .lb-game-container {
                width: 100%;
                height: 85%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .lb-footer-container {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.swal-popup-border {
  border: 3px solid #ffcc00; /* Example border color */
  border-radius: 10px; /* Optional for rounded corners */
}
