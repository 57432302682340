.ct-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #4a0100, #ce0100, #ce0100, #4a0100);

    .ct-header-back {
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
    .ct-header-title {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gold;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-shadow: 2px 2px black;
    }
    .ct-header-empty {
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
