.suggestion-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sg-header-container {
        width: 100%;
        height: 10%;
    }

    .sg-main-container {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 24px;
        gap: 10px;

        .sg-type {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: gold;
            gap: 10px;

            .sg-title {
                width: 20%;
                height: 100%;
                display: flex;
                justify-content: end;
                align-items: center;
            }

            .sg-box {
                width: 60%;
                height: 100%;
                border: 1px solid gold;
                border-radius: 15px;
                background-color: rgb(0, 0, 0, 0.7);
            }
        }

        .sg-message {
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: gold;
            gap: 10px;

            .sg-title {
                width: 20%;
                height: 100%;
                display: flex;
                justify-content: end;
                align-items: center;
            }
            .sg-box {
                width: 60%;
                height: 100%;
                border: 1px solid gold;
                border-radius: 15px;
                background-color: rgb(0, 0, 0, 0.7);
            }
        }
    }
}
