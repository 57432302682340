.v-annc-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;

    .annc-wrapper {
        width: 85%;
        height: 100%;
        border: 2px solid red;
        border-radius: 100px;
        color: gold;
        position: relative;

        .annc-content {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 70%;
            transform: translate(-50%, -50%);
            white-space: nowrap; /* Prevent the text from wrapping into multiple lines */
            display: inline-block; /* Keeps the content inline */
        }

        .annc-content p {
          margin: 0; /* Remove any default margins */
          white-space: nowrap; /* Prevent the paragraph from wrapping */
        }
    }
    .menu-btn-container {
        width: 10%;
        aspect-ratio: 1/1;
        left: 0;
        top: 0;

        .menu-btn-img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
    .annc-btn-container {
        height: 100%;
        aspect-ratio: 1/1;
        position: absolute;
        left: -1%;
        top: 0;

        .annc-btn-img {
            height: 100%;
            aspect-ratio: 1/1;
        }
    }
}

.h-annc-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .annc-wrapper {
        width: 95%;
        height: 50%;
        border: 2px solid red;
        border-radius: 100px;
        color: gold;
        position: relative;

        .annc-content {
            width: 99%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap; /* Prevent the text from wrapping into multiple lines */
            display: inline-block; /* Keeps the content inline */
            max-width: 100%; /* Ensure it doesn't exceed the width of the container */
        }

        .annc-content p {
          margin: 0; /* Remove any default margins */
          white-space: nowrap; /* Prevent the paragraph from wrapping */
        }
    }

    .menu-btn-container {
        display: none;
    }

    .annc-btn-container {
        height: 95%;
        aspect-ratio: 1/1;
        position: absolute;
        left: 0;
        top: 0;

        .annc-btn-img {
            height: 100%;
            aspect-ratio: 1/1;
        }
    }
}
