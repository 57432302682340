.footer-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ft-suggestion-container {
        height: 90%;
        aspect-ratio: 1.5/1;
        cursor: pointer;

        .ft-suggestion-img {
            height: 100%;
            aspect-ratio: 1.5/1;
        }

        &.true {
            display: none;
        }
    }
    .ft-jackpot-container {
        height: 90%;
        aspect-ratio: 3/1;
        cursor: pointer;
        position: relative;

        .ft-jackpot-img {
            height: 100%;
            aspect-ratio: 3/1;
        }

        .ft-jackpot-text {
            color: gold;
            position: absolute;
            top: 75%;
            left: 50%;
            transform: translate(-50%, -40%);
            font-family: Arial;
            font-weight: bolder;
                
            /* Add these properties for text truncation */
            white-space: nowrap; /* Prevents the text from wrapping */
            overflow: hidden; /* Ensures that overflow is hidden */
            text-overflow: ellipsis; /* Adds an ellipsis at the point of truncation */
            max-width: 100px; /* Adjust this width as needed */
        
            /* Fix the font size using a fixed unit */
            font-size: 16px; /* Adjust this value to your needs */
            
            /* Disable zoom from changing the font size */
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        /* Media query for small devices like phones */
        @media (max-width: 800px) {
            .ft-jackpot-text {
                font-size: 14px; /* Adjust for smaller screens */
            }
        }

        /* Media query for small devices like phones */
        @media (max-width: 600px) {
            .ft-jackpot-text {
                font-size: 12px; /* Adjust for smaller screens */
            }
        }
        
        /* Media query for very small devices */
        @media (max-width: 400px) {
            .ft-jackpot-text  {
                font-size: 10px; /* Further adjust for smaller screens */
            }
        }
        
        /* Media query for very small devices */
        @media (max-width: 200px) {
            .ft-jackpot-text {
                font-size: 8px; /* Further adjust for smaller screens */
            }
        }
    }
    
    .ft-transaction-container {
        height: 90%;
        aspect-ratio: 1.5/1;
        cursor: pointer;

        .ft-transaction-img {
            height: 100%;
            aspect-ratio: 1.5/1;
        }

        &.true {
            display: none;
        }
    }
}
