.v-game-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    //align-items: start;

    .game-wrapper {
        width: 100%;
        //height: 100%;
        max-height: 67vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;

        .game {
            width: calc(100% / 3 - 10px);
            aspect-ratio: 1;
            border-style: groove;
            border-color: gold;
            cursor: pointer;
            box-sizing: border-box; /* Include padding and border in the element's total width */

            .game-img {
                //filter: grayscale(0.5);
                position:relative;
                opacity: 1;
                height: 100%;
                aspect-ratio: 1/1;
            }
        }
    }
}

.h-game-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;

    .game-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: start;
        overflow-x: hidden;
        overflow-y: auto;

        .game {
            width: calc(20% - 10px); /* Adjust width to accommodate padding */
            aspect-ratio: 1;
            border-style: groove;
            border-color: gold;
            cursor: pointer;
            box-sizing: border-box; /* Include padding and border in the element's total width */

            .game-img {
                //filter: grayscale(0.5);
                position:relative;
                opacity: 1;
                height: 100%;
                aspect-ratio: 1/1;
            }
        }
    }
}
