.v-settings-modal-container {

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Ensure the body takes the full height of the modal
  }

  .ant-modal-header, .ant-modal-footer {
    background-color: transparent;
    border: none;
  }
  
  .modal-image {
    max-width: 100%; // Limit the maximum width
    max-height: 100%; // Limit the maximum height
  }

  .form-overlay {
    position: absolute;
    top: 45%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    //padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 70%; /* Takes full width of the image */
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-row {
    left: 0%; 
    display: grid;
    grid-template-columns: 1fr 2fr; /* Label column (1fr) and input column (2fr) */
    align-items: center;
  }
  
  .form-row-username {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between items */
  }
  
  .label-image {
    margin-right: 15px;
    height: 25px; /* Adjust label image size */
    justify-self: start; /* Align label images to the start of the grid */
  }

  .form-row .ant-input,
  .form-row .ant-input-password {
    width: 90%;
    margin-left: 10%;
  }

  .text {
    color: white;
    //display: block;
    //white-space: nowrap; // Prevent text wrapping if needed
    //overflow: hidden; // Hide overflow text
    text-overflow: ellipsis; // Add ellipsis for overflow text
    font-family: 'Arial Black', sans-serif;
    font-size: 1.00rem; // Adjust size
    font-weight: bold;// Make it bold
  }
  
  /* White Radio Button Text */
  .white-radio .ant-radio-wrapper {
    color: white;
  }
  
  .white-radio .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
  }
  
  .white-radio .ant-radio-inner::after {
    background-color: #1890ff;
  }
  
  .ant-slider-track {
    background: linear-gradient(to right, #d4af37, #ffd700, #ffec8b, #b8860b, #ffd700, #d4af37);
  }
  
  /* Style when the value is 50 or more */
  .on-tooltip .ant-slider-tooltip {
    display:block;
  }

  /* Style when the value is less than 50 */
  .off-tooltip .ant-slider-tooltip {
    display: none;
  }
  
  .logout-btn {
    //position: absolute;
    //top: 5px; // Adjust these values as needed
    //left: 230px; // Position from the right side
    //z-index: 1; // Ensure the button appears above other elements
    //width: 15%; // Adjust size as needed
    //cursor: pointer;    /* z-index: 1; */
    width: 12.5%;
    cursor: pointer;
    margin-left: 2.5%;
  }
  
  .submit-button {
    padding: 0; /* Remove default padding */
    border: none; /* Remove border if desired */
    background: transparent; /* Make background transparent */
  }
  
  .submit-btn {
    position: absolute;
    top: 75%;
    left: 12.5%;
    z-index: 1;
    width: 75%;
    cursor: pointer;
  }
  
  .cancel-btn {
    position: absolute;
    top: 275%;
    left: 12.5%;
    z-index: 1;
    width: 75%;
    cursor: pointer;
  }
  
}

.h-settings-modal-container {

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Ensure the body takes the full height of the modal
  }

  .ant-modal-header, .ant-modal-footer {
    background-color: transparent;
    border: none;
  }
  
  .modal-image {
    max-width: 100%; // Limit the maximum width
    max-height: 100%; // Limit the maximum height
  }

  .form-overlay {
    position: absolute;
    top: 57.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%; /* Takes full width of the image */
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 3fr; /* Label column (1fr) and input column (2fr) */
    align-items: center;
  }
  
  .label-image {
    height: 30px; /* Adjust label image size */
    justify-self: start; /* Align label images to the start of the grid */
    margin-right: 10%;
  }

  .form-row .ant-input,
  .form-row .ant-input-password {
    width: 90%;
    margin-left: 10%;
  }

  .text {
    color: white;
    //display: block;
    //white-space: nowrap; // Prevent text wrapping if needed
    //overflow: hidden; // Hide overflow text
    text-overflow: ellipsis; // Add ellipsis for overflow text
    font-family: 'Arial Black', sans-serif;
    font-size: 1.25rem; // Adjust size
    font-weight: bold;// Make it bold
  }
  
  /* White Radio Button Text */
  .white-radio .ant-radio-wrapper {
    color: white;
  }
  
  .white-radio .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff;
  }
  
  .white-radio .ant-radio-inner::after {
    background-color: #1890ff;
  }
  
  .ant-slider-track {
    background: linear-gradient(to right, #d4af37, #ffd700, #ffec8b, #b8860b, #ffd700, #d4af37);
  }
  
  /* Style when the value is 50 or more */
  .on-tooltip .ant-slider-tooltip {
    display:block;
  }

  /* Style when the value is less than 50 */
  .off-tooltip .ant-slider-tooltip {
    display: none;
  }
  
  .logout-btn {
    //position: absolute;
    //right: 100px; // Position from the right side
    //z-index: 1; // Ensure the button appears above other elements
    //width: 10%; // Adjust size as needed
    //cursor: pointer;
    width: 10%;
    cursor: pointer;
    margin-left: 2.5%;
    margin-bottom: 2.5%;
  }
  
  .submit-button {
    padding: 0; /* Remove default padding */
    border: none; /* Remove border if desired */
    background: transparent; /* Make background transparent */
  }
  
  .submit-btn {
    position: absolute;
    top: 50%; // Adjust these values as needed
    left: 50%; // Adjust these values as needed
    z-index: 1; // Ensure the button appears above other elements
    width: 40%; // Adjust size as needed
    cursor: pointer;
  }
  
  .cancel-btn {
    position: absolute;
    top: 50%; // Adjust these values as needed
    left: 5%; // Adjust these values as needed
    z-index: 1; // Ensure the button appears above other elements
    width: 40%; // Adjust size as needed
    cursor: pointer;
  }
  
}

.swal-popup-border {
  border: 3px solid #ffcc00; /* Example border color */
  border-radius: 10px; /* Optional for rounded corners */
}
