.v-game-cat-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    .cat-title {
        display: none;
    }

    .cat {
        height: 100%;
        aspect-ratio: 1/1;
        //padding: 10px;
        cursor: pointer;

        .cat-img {
            height: 100%;
            aspect-ratio: 1/1;

            &.false {
                opacity: 0.5;
            }
        }
    }
}

.h-game-cat-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    .cat-title {
        width: 100%;
        min-height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        .cat-img {
            width: 100%;
            aspect-ratio: 4/1;
        }
    }

    .cat {
        width: 60%;
        aspect-ratio: 1/1;
        padding: 10px;
        cursor: pointer;

        .cat-img {
            width: 100%;
            aspect-ratio: 1/1;

            &.false {
                opacity: 0.5;
            }
        }
    }
}
